import http from '@/libs/http'
import { useStore } from '@/store'
import axios from 'axios'
const url = process.env.VUE_APP_MICROSERVICE_API_CONTRATO_PRECIO

export default class SolicitudContratoService {
  paginarSolicitudes (params) {
    return http.get(`${url}/solicitudes/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  obtenerClientes () {
    return http.get(`${url}/solcitudes/clientes`)
  }

  buscarContratos (params) {
    return http.get(`${url}/solicitudes/buscar-contratos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  buscarMedicamentos (params) {
    return http.get(`${url}/solicitudes/buscar-medicamentos`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  crearSolicitud (payload) {
    return http.post(`${url}/solicitudes`, payload, {
      headers: {
        loading: true
      }
    })
  }

  buscarDetalle (id) {
    return http.get(`${url}/solicitudes/obtener-detalle-solicitud/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  responderSolicitud (payload) {
    return http.put(`${url}/solicitudes/responder-solicitud`, payload, {
      headers: {
        loading: true
      }
    })
  }

  validarSolicitidContratoPrecio (params) {
    return http.get(`${url}/solicitudes/validarMxListaPrecio`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  buscarSolicitante (params) {
    return http.get(`${url}/solicitudes/buscar-solicitantes`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  verPdf (ruta) {
    const store = useStore()
    return new Promise((resolve) => {
      const baseUrl = `${url}/solicitudes/ver-pdf`
      const token = store.getters['auth/getToken']
      const res = axios.getUri({ url: baseUrl, params: { token: `Bearer ${token}`, path: ruta } })
      resolve(res)
    })
  }
}
