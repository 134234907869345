<template>
  <section>
    <div class="w-full mt-2 box p-4">
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <i class="pi pi-list"></i>
          <p class="text-lg font-bold">Listado de solicitudes</p>
        </div>
        <div>
          <Button @click="$router.push({ name: 'pharmasan.ventas.contrato-precio.solicitudes.crear' })" label="Crear solicitud" icon="pi pi-plus" />
        </div>
      </div>
      <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2">
        <div class="w-full">
          <label class="text-gray-600 text-xs italic"># Solicitud</label>
          <InputNumber v-model="filtros.Id" class="w-full" inputId="withoutgrouping" :useGrouping="false" />
        </div>
        <div class="w-full">
          <label class="text-gray-600 text-xs italic">Contrato</label>
          <AutoComplete
            size="small"
            id="cliente"
            v-model="contratoBuscado"
            dropdown class="w-full h-min"
            panelClass="text-xs"
            dropdownClass="h-10"
            inputClass="h-min text-sm"
            :suggestions="solicitudContratosStore._listado_contratos"
            @complete="buscarContratos($event)"
            optionLabel="Descript"
            @input="filtros.ContratoId = null"
            :forceSelection="true"
            @item-select="filtros.ContratoId = contratoBuscado.Id"
          >
            <template #option="slotProps">
              <div class="w-full flex items-center gap-2">
                <div>
                  <p>
                    {{ slotProps.option.CardCode + ' - ' + slotProps.option.Cliente.U_PHR_CardName }}
                  </p>
                  <div class="flex gap-2">
                    <span class="font-medium">Tipo de contrato</span>
                    {{ slotProps.option?.CtoMotAutoriza[0]?.MotAutoriza?.ModContrato?.descripcion }}
                  </div>
                  <div class="flex gap-2">
                    <span class="font-medium">Descripción</span>
                    <p>{{ slotProps.option.Descript }}</p>
                  </div>
                  <div class="flex gap-2">
                    <span class="font-medium">Motivos autorización</span>
                    <div v-for="(motivo, i) of slotProps.option.CtoMotAutoriza" :key="i">
                      {{ motivo?.MotAutoriza?.Nombre }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="w-full" v-if="$can('pharmasan.ventas.contrato-precio.solicitudes.gestion')">
          <label class="text-gray-600 text-xs italic">Solicitantes</label>
          <AutoComplete
            size="small"
            id="cliente"
            v-model="solicitanteBuscado"
            dropdown class="w-full h-min"
            panelClass="text-xs"
            dropdownClass="h-10"
            inputClass="h-min text-sm"
            @input="filtros.createdBy = null"
            :suggestions="solicitudContratosStore._solicitantes_buscados"
            @complete="buscarSolicitantes($event)"
            :forceSelection="true"
            optionLabel="full_name"
            @item-select="filtros.createdBy = solicitanteBuscado.createdBy"
          ></AutoComplete>
        </div>
        <div>
          <label class="text-gray-600 text-xs italic">Gestion de solicitudes</label>
          <Dropdown v-model="filtros.tipo_solicitud" :options="tiposSolicitudes" optionLabel="nombre" optionValue="tipo" input-class="text-sm" class="w-full md:w-14rem text-xs" />
        </div>
        <div class="w-full grid grid-cols-2 gap-2">
          <Button label="Buscar" @click="paginarSolicitudes" class="w-full mt-5" icon="pi pi-search" />
          <Button label="Limpiar" @click="limpiarFiltros" severity="secondary" class="w-full mt-5" icon="pi pi-times" />
        </div>
      </div>
      <div class="w-full">
        <DataTable v-model:expandedRows="expandedRows" showGridlines @rowExpand="onRowExpand" class="mt-2 text-sm" :value="solicitudContratosStore._paginado_solicitudes.rows" tableStyle="min-width: 50rem">
          <Column expander :style="{ width: '5rem' }" />
          <Column field="Id" header="# Solicitud"></Column>
          <Column field="Contrato.NumCto" header="Contrato">
            <template #body="slotProps">
              {{ (slotProps.data.NumeroContrato || '') + ' - ' + (slotProps.data.ContratoDescripcion || '') }}
            </template>
          </Column>
          <Column field="createdAt" header="Fecha de la creación">
            <template #body="slotProps">
              {{ $h.formatDate(slotProps.data.createdAt, 'YYYY-MM-DD HH:mm') }}
            </template>
          </Column>
          <Column field="MxGestionados" header="Mx gestionados"></Column>
          <Column field="MxNoGestionados" header="Mx sin Gestionar"></Column>
          <Column field="SolicitadoPor" header="Solicitado por"></Column>
          <template #expansion="slotProps">
            <div v-if="slotProps">
              <DataTable scrollable scrollHeight="50vh" :value="solicitudContratosStore._solicitud_detalle" class="text-xs" tableStyle="min-width: 50rem">
                <Column field="Articulo.ItemCode" header="Código medicamento"></Column>
                <Column field="Articulo.ItemName" header="Descripción"></Column>
                <Column field="Agrupacion" header="Agrupacion"></Column>
                <Column field="Comentarios" header="Comentarios"></Column>
                <Column field="Soporte" header="Adjunto" :style="{ width: '5rem' }">
                  <template #body="slotPropsChild">
                    <div v-if="slotPropsChild.data.Soporte">
                      <Button @click="solicitudContratosStore.verPdf(slotPropsChild.data.Soporte)" icon="pi pi-paperclip" text rounded aria-label="Filter" />
                    </div>
                  </template>
                </Column>
                <Column field="Status" header="Estado" :style="{ width: '5rem' }">
                  <template #body="slotPropsChild">
                    <div v-if="slotPropsChild.data.Status === 0" class="p-1 bg-gray-600 font-bold text-white rounded-md text-xs">
                      <p>Pendiente</p>
                    </div>
                    <div v-if="slotPropsChild.data.Status === 1" class="p-1 bg-green-600 font-bold text-white rounded-md text-xs">
                      <p>Aprobado</p>
                    </div>
                    <div v-if="slotPropsChild.data.Status === 2" class="p-1 bg-red-600 font-bold text-white rounded-md text-xs">
                      <p>Rechazado</p>
                    </div>
                  </template>
                </Column>
                <Column field="Status" header="Acciones" v-if="$can('pharmasan.ventas.contrato-precio.solicitudes.gestion')" class="w-full text-center" :style="{ width: '5rem' }">
                  <template #body="slotPropsChild">
                    <div v-if="slotPropsChild.data.Status === 0" class="flex gap-2">
                      <Button size="small" @click="abrirModalAceptarSolicitud(slotPropsChild.data, 1, slotProps.data)" label="Aceptar" icon="pi  pi-check" />
                      <Button size="small" @click="abrirModalAceptarSolicitud(slotPropsChild.data, 2)" label="Rechazar" icon="pi pi-times" severity="danger" />
                    </div>
                    <div v-else class="flex gap-2 text-green-600 w-full text-center justify-center">
                      <i class="pi pi-check text-xs"></i>
                      <p class="font-bold">Gestionado</p>
                    </div>
                  </template>
                </Column>
                <Column field="Response"  :style="{ width: '25rem', height: '4rem' }" class="overflow-y-auto" header="Respuesta">
                  <template #body="slotPropsChild">
                    <div v-if="slotPropsChild.data.Status !== 0" class="w-full h-12 overflow-y-auto">
                      <div class="flex gap-2">
                        <p class="font-bold">Respondido por:</p>
                        <p>{{ slotPropsChild.data?.ResponsableRespuesta?.Persona?.full_name }}</p>
                      </div>
                      <div class="flex gap-2">
                        <p class="font-bold">Fecha de respuesta:</p>
                        <p>{{ slotPropsChild.data.updatedAt ? $h.formatDate(slotPropsChild.data.updatedAt, 'DD/MM/YYYY HH:mm') : 'SIN REGISTRO' }}</p>
                      </div>
                      <div class="flex gap-2">
                        <p class="font-bold">Observaciones:</p>
                        <p>{{ slotPropsChild.data.Response }}</p>
                      </div>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
        <Paginator :rows="limit" @page="onPage" :totalRecords="solicitudContratosStore._paginado_solicitudes.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
      </div>
    </div>
    <Dialog v-model:visible="displayModalSolicitudResponse" :closable="false" modal header="Header" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <template #header>
        <div v-if="solicitudResponse.Status === 1" class="flex gap-2 items-center text-green-600">
          <!-- <i class="pi pi-check"></i> -->
          <i class="pi pi-spin pi-spinner"></i>
          <p>Aprobar solicitud para el medicamento <span class="font-bold">{{ solicitudResponse.detail?.Articulo?.ItemCode + ' - ' + (solicitudResponse.detail?.solicitud?.NumeroContrato || '') }}</span></p>
        </div>
        <div v-if="solicitudResponse.Status === 2" class="flex gap-2 font-bold items-center text-red-600">
          <i class="pi pi-spin pi-spinner"></i>
          <p>Rechazar solicitud</p>
        </div>
      </template>
      <div v-if="solicitudResponse.Status === 1" class="w-full mb-2">
        <label for="precio" class="text-xs text-gray-600 italic">Precio</label>
        <p v-if="solicitudResponse.Price" class="italic text-gray-600">{{ $h.formatCurrency(+solicitudResponse.Price) }}</p>
        <InputNumber v-else type="text" id="precio" class="w-full" mode="currency" currency="COP" locale="es-CO" v-model="solicitudResponse.PriceSave" />
      </div>
      <div>
        <label for="observaciones" class="text-xs text-gray-600 italic">Observaciones</label>
        <Textarea id="observaciones" v-model="solicitudResponse.Response" class="w-full" rows="4" />
      </div>
      <div class="flex justify-end mt-2 gap-2">
        <Button label="Guardar" @click="guardarRespuestaSolicitud" />
        <Button label="Cancelar" @click="cerrarModalAceptarSolicitud" severity="secondary" />
      </div>
    </Dialog>
  </section>
</template>
<script setup>
  import { useSolicitudContratosStore } from '../../../stores/solicitud-contrato.store'
  // import { useContratosStore } from '../../../stores/contratos.store'
  import { onMounted, ref, computed } from 'vue'
  import Swal from 'sweetalert2'
  const solicitudContratosStore = useSolicitudContratosStore()
  // const contratosStore = useContratosStore()
  const limit = ref(10)
  const offset = ref(0)
  const expandedRows = ref([])
  const displayModalSolicitudResponse = ref(false)
  const contratoBuscado = ref('')
  const solicitanteBuscado = ref('')
  const filtros = ref({
    Id: null,
    ContratoId: null,
    createdBy: null,
    tipo_solicitud: null
  })
  const tiposSolicitudes = ref([
    {
      nombre: 'Solicitudes gestionadas',
      tipo: 1
    },
    {
      nombre: 'Solicitudes sin gestionar',
      tipo: 2
    },
    {
      nombre: 'Todas las solicitudes',
      tipo: 3
    }
  ])
  const params = computed(() => {
    return {
      offset: offset.value,
      limit: limit.value,
      ...filtros.value
    }
  })
  const solicitudResponse = ref({
    Status: 0,
    Response: '',
    detail: {},
    Price: 0,
    CtoArt: false,
    PriceSave: 0,
    ListPriceCL: 0
  })
  const paginarSolicitudes = () => {
    solicitudContratosStore.paginarSolicitudes(params.value)
  }
  const limpiarFiltros = () => {
    filtros.value = {
      Id: null,
      ContratoId: null,
      createdBy: null,
      tipo_solicitud: null
    }
    contratoBuscado.value = ''
    solicitanteBuscado.value = ''
    paginarSolicitudes()
  }
  const onPage = ({ first, rows }) => {
    offset.value = first
    limit.value = rows
    paginarSolicitudes()
  }
  const onRowExpand = (event) => {
    solicitudContratosStore.buscarDetalle(event.data.Id)
    expandedRows.value = expandedRows.value.filter(a => a.Id === event.data.Id)
  }
  const abrirModalAceptarSolicitud = (dataDetalleSolicitud, type, solicitud) => {
    solicitudResponse.value.Status = type
    solicitudResponse.value.detail = { ...dataDetalleSolicitud, solicitud }
    if (type === 1) {
      solicitudContratosStore.validarSolicitud({ idSolicitudDetalle: dataDetalleSolicitud.Id }).then((data) => {
        solicitudResponse.value.Price = data.Price
        solicitudResponse.value.CtoArt = data.CtoArt
        solicitudResponse.value.PriceSave = 0
        solicitudResponse.value.ListPriceCL = data.ListPriceCL
      })
    }
    displayModalSolicitudResponse.value = true
  }
  const cerrarModalAceptarSolicitud = () => {
    solicitudResponse.value = {
      Status: 0,
      Response: '',
      detail: {},
      Price: 0,
      CtoArt: false,
      PriceSave: 0,
      ListPriceCL: 0
    }
    displayModalSolicitudResponse.value = false
  }
  const guardarRespuestaSolicitud = () => {
    const payload = {
      ContratoId: solicitudResponse.value.detail.solicitud?.ContratoId,
      Response: solicitudResponse.value.Response,
      Id: solicitudResponse.value.detail.Id,
      ArticuloId: solicitudResponse.value.detail?.ArticuloId,
      ItemCode: solicitudResponse.value.detail.Articulo.ItemCode,
      ListPriceCL: solicitudResponse.value.detail?.solicitud?.ListPrice,
      Status: solicitudResponse.value.Status,
      CtoArt: solicitudResponse.value.CtoArt
    }
    if (solicitudResponse.value.PriceSave) payload.Price = solicitudResponse.value.PriceSave
    solicitudContratosStore.responderSolicitud(payload).then((data) => {
      if (data) {
        Swal.fire({
          title: 'Guardado',
          text: 'Solicitud gestionada con éxito',
          icon: 'success'
        }).then(() => {
          onRowExpand({ data: { Id: solicitudResponse.value.detail.Id } })
          // expandedRows.value = [solicitudContratosStore._paginado_solicitudes.rows.find(a => a.Id === solicitudResponse.value.detail.Id)]
          cerrarModalAceptarSolicitud()
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al gestionar la solicitud, intenta nuevamente'
        })
      }
    })
  }
  const buscarContratos = (event) => {
    solicitudContratosStore.buscarContratos({ name: event.query })
  }
  const buscarSolicitantes = (event) => {
    solicitudContratosStore.buscarSolicitante({ name: event.query ? event.query : 'a' })
  }
  onMounted(() => {
    paginarSolicitudes()
  })
</script>
<style>
    ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
